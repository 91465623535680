import Error from "./error";

export default class FormUtils {
    static formToParams(form) {
        let res = {}
        for (let k in form) {
            res[k] = form[k].value
            if (typeof res[k] === 'string') {
                res[k] = res[k].trim()
            }
        }
        return res
    }

    static addValidationErrors(form, errors) {
        for (let e in errors) {
            let error = new Error(errors[e])
            if (Object.prototype.hasOwnProperty.call(form, error.field)) {
                form[error.field].state = false
                form[error.field].feedback = error.message
            }
        }
    }

    static clearSingleValidationError(formItem) {
        formItem.state = null
        formItem.feedback = null
    }

    static clearAllValidationErrors(form) {
        for (let i in form) {
            this.clearSingleValidationError(form[i])
        }
    }

    static clearForm(form) {
        for (let k in form) {
            form[k].value = null
            form[k].feedback = null
            form[k].state = null
        }
    }
}
