<template>
  <div>
    <div v-if="!loading">
      <b-form-group
          v-for="(item, name) in form"
          :key="name"
          :label="item.label"
          :invalid-feedback="item.feedback"
          :state="item.state"
      >
        <b-form-input
            :filter-type="item.type"
            type="text"
            :placeholder="item.placeholder"
            v-model="item.value"
            :state="item.state"
            :formatter="item.filter"
            @input="clearError(item)"
        ></b-form-input>
      </b-form-group>
      <b-button variant="success" @click="create" v-if="canCreate">Создать</b-button>
    </div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <b-alert v-model="createInfo.show" class="position-fixed fixed-top m-0 rounded-0" :variant="createInfo.variant">
      {{ createInfo.message }}
    </b-alert>
  </div>
</template>

<script>
import FormItem from "../../mixins/form-item";
import FormUtils from "../../mixins/form-utils";
import rpc from "../../rpc";

export default {
  name: "CreateCollector",
  data() {
    return {
      loading: false,
      createInfo: {
        show: false,
        message: null,
        variant: 'success'
      },
      form: {
        name: new FormItem({placeholder: 'Введите название', label: 'Название', type: 'name'}),
        email: new FormItem({placeholder: 'Введите E-mail', label: 'E-mail', type: 'email'}),
        phone: new FormItem({placeholder: 'Введите телефон', label: 'Телефон', type: 'phone'}),
        inn: new FormItem({placeholder: 'Введите ИНН', label: 'ИНН', type: 'inn'}),
        ogrn: new FormItem({placeholder: 'Введите ОГРН', label: 'ОГРН', type: 'ogrn'}),
        address: new FormItem({placeholder: 'Введите адрес', label: 'Адрес'}),
        headType: new FormItem({placeholder: 'Введите тип руководителя (директор, генеральный директор и т.д.)', label: 'Тип руководителя', type: 'name'}),
        headName: new FormItem({placeholder: 'Введите ФИО руководителя', label: 'ФИО руководителя', type: 'name'}),
        headGender: new FormItem({placeholder: 'Введите пол руководителя (m для мужского, f для женского)', label: 'Пол руководителя', type: 'gender'}),
        mainDocument: new FormItem({placeholder: 'Введите наименование уставного документа', label: 'Наименование уставного документа', type: 'name'}),
        agentDocName: new FormItem({placeholder: 'Введите наименование агентского договора', label: 'Наименование агентского договора'}),
        agentDocDate: new FormItem({placeholder: 'Введите дату агентского договора', label: 'Дата агентского договора', type: 'date'}),
        login: new FormItem({placeholder: 'Введите логин', label: 'Логин', type: 'login'}),
        password: new FormItem({placeholder: 'Введите пароль', label: 'Пароль', type: 'password'}),
      }
    }
  },
  methods: {
    clearError(item) {
      FormUtils.clearSingleValidationError(item)
    },
    create() {
      this.loading = true
      rpc.createCollectorAgency(FormUtils.formToParams(this.form)).then((result) => {
        this.createInfo.message = 'Создано коллекторское агентство с названием "' + result.name + '"'
        this.createInfo.variant = 'success'
        this.createInfo.show = true
        setTimeout(() => {
          this.createInfo.show = false
          this.createInfo.message = null
        }, 1500)
        FormUtils.clearForm(this.form)
        this.loading = false
      }).catch((err) => {
        if (typeof err.data === 'object') {
          FormUtils.addValidationErrors(this.form, err.data)
        } else {
          this.createInfo.message = 'Произошла ошибка'
          this.createInfo.variant = 'warning'
          this.createInfo.show = true
          setTimeout(() => {
            this.createInfo.show = false
            this.createInfo.message = null
          }, 1500)
        }

        this.loading = false
      })
    }
  },
  computed: {
    canCreate: function () {
      let canCreate = true
      for (let k in this.form) {
        if (this.form[k].value === null || this.form[k].value === '') {
          canCreate = false
          break
        }
        if (this.form[k].state !== null) {
          canCreate = false
          break
        }
      }
      return canCreate
    }
  }
}
</script>

<style scoped>

</style>