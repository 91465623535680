<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :busy="table.busy"
      :current-page="table.page"
      :items="table.items"
      :fields="table.fields"
    >
      <template #cell(active)="data">
        {{ data.value ? 'Активно' : 'Деактивировано' }}
      </template>
      <template #cell(actions)="data">
        <b-spinner v-if="actionLoading"></b-spinner>
        <b-dropdown v-if="!actionLoading" text="Действия" variant="primary">
          <b-dropdown-item variant="danger" v-if="data.item.active" @click="deactivate(data.item.id)">Деактивировать</b-dropdown-item>
          <b-dropdown-item variant="warning" v-if="!data.item.active" @click="activate(data.item.id)">Активировать</b-dropdown-item>
          <b-dropdown-item variant="primary" @click="resetPassword(data.item.id)">Сбросить пароли</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      pills
      v-model="table.page"
      :total-rows="table.count"
      :per-page="table.limit"
    ></b-pagination>
    <b-modal title="Новые учётные данные" ref="newPasswords" no-close-on-esc hide-header-close hide-footer>
      <b-table
          responsive
          :items="newPasswords"
          :fields="[{key: 'login', label: 'Логин'}, {key: 'password', label: 'Пароль'}]"
          striped
          hover
      ></b-table>
      <b-button variant="success" @click="hideNewPasswords">Готово</b-button>
    </b-modal>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import rpc from "../../rpc";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "CollectorsTable",
  components: {ErrorHandler},
  data() {
    return {
      errorMessage: null,
      actionLoading: false,
      newPasswords: [],
      table: new Table({
        fields: [
          {
            key: 'name',
            label: 'Название',
          },
          {
            key: 'email',
            label: 'E-mail',
          },
          {
            key: 'phone',
            label: 'Телефон',
          },
          {
            key: 'active',
            label: 'Активность',
          },
          {
            key: 'balance',
            label: 'Процент займов'
          },
          {
            key: 'actions',
            label: 'Действия'
          }
        ]
      })
    }
  },
  methods: {
    getAgencies() {
      this.table.busy = true
      rpc.getCollectorAgencies(this.table.limit, this.table.calculateOffset()).then((result) => {
        this.table.count = result.count
        this.table.items = result.data
        this.table.busy = false
      }).catch(() => {
        this.errorMessage = 'Ошибка получения списка коллекторских агентств'
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    },
    deactivate(id) {
      this.actionLoading = true
      rpc.changeCollectorActivity(id, false).then(() => {
        this.actionLoading = false
        this.getAgencies()
      })
    },
    activate(id) {
      this.actionLoading = true
      rpc.changeCollectorActivity(id, true).then(() => {
        this.getAgencies()
        this.actionLoading = false
      })
    },
    resetPassword(id) {
      this.actionLoading = true
      rpc.resetPassword(id).then((res) => {
        this.newPasswords = res
        this.$refs.newPasswords.show()
        this.actionLoading = false
      }).catch(() => {
        this.errorMessage = 'Ошибка сброса пароля'
        this.actionLoading = false
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    },
    hideNewPasswords() {
      this.newPasswords = []
      this.$refs.newPasswords.hide()
    }
  },
  mounted() {
    this.getAgencies()
  },
  watch: {
    'table.page': function () {
      this.getAgencies()
    }
  }
}
</script>

<style scoped>

</style>